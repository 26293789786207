import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Link } from "gatsby";
import { NavDropdown } from "react-bootstrap";

import getBaseUrl from "utils/getBaseUrl";
// import IconText from "components/IconText";

import "./LanguageSelector.scss";

// NavDropdown -> title={<IconText iconName="LanguageIcon" text={langKey}/>}
const LanguageSelector = ({ langKey, defaultLang, langTextMap, page }) => {
  return (
    <NavDropdown
      title={<span className="text-uppercase">{langKey}</span>}
      id="language-dropdown"
      className="language-selector"
      alignRight
    >
      {Object.keys(langTextMap).map((key) => {
        return (
          <Link
            key={key}
            to={getBaseUrl(defaultLang, key) + page}
            className={clsx("dropdown-item", { active: key === langKey })}
          >
            {langTextMap[key]}
          </Link>
        );
      })}
    </NavDropdown>
  );
};

LanguageSelector.propTypes = {
  defaultLang: PropTypes.string.isRequired,
  langKey: PropTypes.string.isRequired,
  langTextMap: PropTypes.object.isRequired,
  page: PropTypes.string.isRequired,
};

LanguageSelector.defaultProps = {};

export default LanguageSelector;
