import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

const SEO = ({ frontmatter, metadata, lang }) => {
  const { site: { siteMetadata: { title } } } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title,
        }
      }
    }
  `);

  const { description: metaDescription, keywords: metaKeywords } = frontmatter;
  const { titleTemplate, keywords, description } = metadata;

  const _titleTemplate = titleTemplate ? `${titleTemplate} – ${title}` : title;
  const _description = description || metaDescription;
  const _keywords = keywords || metaKeywords;

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={_titleTemplate}
      meta={[
        {
          name: `description`,
          content: _description,
        },
        {
          name: `keywords`,
          content: _keywords,
        },
        {
          property: `og:title`,
          content: _titleTemplate,
        },
        {
          property: `og:description`,
          content: _description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: "RoutinesLab",
        },
        {
          name: `author`,
          content: "https://www.routineslab.com",
        },
      ]}
    />
  );
};

SEO.propTypes = {
  frontmatter: PropTypes.object,
  metadata: PropTypes.object,
  lang: PropTypes.string,
};

SEO.defaultProps = {
  frontmatter: {
    description: "",
    keywords: ""
  },
  metadata: {
    titleTemplate: "",
    description: "",
    keywords: "",
  },
  lang: "de",
};

export default SEO;
