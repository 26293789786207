import React from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import { scroller } from "react-scroll";

const NavDropdownItem = ({ page, anchor, to, onClick }) => {
  const handleClick = () => {
    if (`/${page}` !== `${to}/`) navigate(`${to}/`);
    setTimeout(() =>
      scroller.scrollTo(anchor, {
        duration: 1500,
        delay: 0,
        offset: -53,
        smooth: "easeInOutQuart",
      }), 500);
    onClick();
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <a
      key={anchor}
      className="dropdown-item cursor-pointer"
      onClick={handleClick}
    >
      {anchor}
    </a>
  );
};

NavDropdownItem.propTypes = {
  page: PropTypes.string.isRequired,
  anchor: PropTypes.string.isRequired,
  to: PropTypes.string,
  onClick: PropTypes.func,
};

NavDropdownItem.defaultProps = {
  to: "",
  onClick: null,
};

export default NavDropdownItem;
