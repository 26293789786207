import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import clsx from "clsx";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";

import getBaseUrl from "utils/getBaseUrl";
import fileNameToSectionName from "utils/fileNameToSectionName";
import useWindowOnScroll from "hooks/useWindowOnScroll";
import Image from "components/Image";
import Icon from "components/Icon";
import NavItem from "components/NavItem";

import "./Navbar.scss";

import LanguageSelector from "components/LanguageSelector";
import NavDropdownItem from "components/NavDropdownItem";

const MyNavbar = ({ frontmatter, pagesNodes, foundersNode, pathContext, page }) => {
  const { imageFileName } = frontmatter;
  const { anchor: anchorFounders } = foundersNode.frontmatter;
  const { langKey, defaultLang, langTextMap } = pathContext;

  const [expanded, setExpanded] = React.useState(false);

  const toggleMenu = React.useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);
  const closeMenu = React.useCallback(() => {
    setExpanded(false);
  }, []);

  const [shrink, setShrink] = React.useState(false);
  const handleWindowScroll = React.useCallback(() => {
    const scrollTop = document.documentElement.scrollTop ||
      document.body.scrollTop;
    setShrink(scrollTop > 100);
  }, []);
  useWindowOnScroll(handleWindowScroll);

  const [show, setShow] = React.useState(false);
  const toggleDropdown = React.useCallback(() => {
    setShow(!show);
  }, [show]);

  let langSelectorPart;
  if (langTextMap != null && Object.keys(langTextMap).length > 1) {
    langSelectorPart = (
      <LanguageSelector
        langKey={langKey}
        defaultLang={defaultLang}
        langTextMap={langTextMap}
        page={page}
      />);
  }

  return (
    <Navbar
      className={clsx("navbar-root text-body", { "navbar-shrink": shrink })}
      expand="md"
      fixed="top"
      expanded={expanded}
      style={{ zIndex: 3 }}
    >
      <Container fluid>
        <Navbar.Brand>
          <Link to={getBaseUrl(defaultLang, langKey)}>
            <Image
              className="img-fluid d-block mx-auto"
              fileName={imageFileName}
              alt={imageFileName}
            />
          </Link>
        </Navbar.Brand>
        <div className='d-flex order-md-1 align-items-center'>


          <Navbar.Toggle onClick={toggleMenu} aria-label="Toggle navigation">
            <Icon iconName="BarsIcon"/>
          </Navbar.Toggle>
        </div>
        <Navbar.Collapse>
          <Nav className="ml-auto text-center py-3 py-md-0">
            {pagesNodes.map(
              ({ fields: { fileName }, frontmatter: { anchor: rootAnchor, sections } }) => {
                const to = getBaseUrl(defaultLang, langKey) +
                  fileNameToSectionName(fileName);

                if (rootAnchor && sections) return (
                  <NavDropdown
                    show={show}
                    key={rootAnchor}
                    title={rootAnchor}
                    className='dropdown-custom'
                    onClick={toggleDropdown}
                  >
                    {sections.map(({ anchor }) => {
                      if (anchor) return (
                        <NavDropdownItem
                          key={anchor}
                          page={page}
                          anchor={anchor}
                          to={to}
                          onClick={() => {
                            closeMenu();
                            toggleDropdown();
                          }}
                        />
                      );
                      return null;
                    })}

                    <NavDropdownItem
                      page={page}
                      anchor={anchorFounders}
                      to={to}
                      onClick={() => {
                        closeMenu();
                        toggleDropdown();
                      }}
                    />
                  </NavDropdown>
                );

                if(rootAnchor ==='Checkout' || rootAnchor === 'Auschecken') return null;

                return rootAnchor ? (
                  <NavItem
                    key={rootAnchor}
                    to={`${to}/`}
                    onClick={closeMenu}
                  >
                    {rootAnchor}
                  </NavItem>
                ) : null;
              },
            )}

            {langSelectorPart}
            
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

MyNavbar.propTypes = {
  frontmatter: PropTypes.object.isRequired,
  pagesNodes: PropTypes.arrayOf(PropTypes.object).isRequired,
  foundersNode: PropTypes.object.isRequired,
  pathContext: PropTypes.object.isRequired,
  page: PropTypes.string.isRequired,
};

MyNavbar.defaultProps = {};

export default MyNavbar;
