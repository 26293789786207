import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import fileNameToSectionName from "utils/fileNameToSectionName";
import breakDownAllNodes from "utils/breakDownAllNodes";
import Navbar from "containers/Navbar";
import Footer from "containers/Footer";
import SEO from "containers/SEO";
import Image from "components/Image";

const Wrapper = ({ pathContext, nodes, node, children, className }) => {
  let { site: { siteMetadata: { banner } } } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          banner
        }
      }
    }
  `);

  const { langKey } = pathContext;
  const { metadataNode, navBarNode, footerNode, pagesNodes, foundersNode } =
    breakDownAllNodes(nodes);

  const {
    frontmatter: { titleTemplate, keywords, description, backgroundImg },
    fields: { directoryName, fileName },
  } = node;

  if (backgroundImg !== undefined ) {
    banner = backgroundImg;
  }

  let page = "";
  if (fileNameToSectionName(fileName) !== "index")
    page = `${fileNameToSectionName(fileName)}/`;
  if (fileNameToSectionName(directoryName) === "products")
    page = `${directoryName}/${fileNameToSectionName(fileName)}/`;

  // const url = typeof window !== 'undefined' ? window.location.pathname : '';
  // console.log(url);

  return (<>
    <SEO
      frontmatter={metadataNode.frontmatter}
      metadata={{ titleTemplate, keywords, description }}
      lang={langKey}
    />
    <Navbar
      frontmatter={navBarNode.frontmatter}
      pagesNodes={pagesNodes}
      foundersNode={foundersNode}
      pathContext={pathContext}
      page={page}
    />
    <Image
      className="image p-0 bg-dark card-img-overlay position-fixed"
      style={{ /* filter: "brightness(70%)", */ opacity: ".5", zIndex: 1 }}
      fileName={banner}
      alt=''
    />
    <div className={`padding-navbar ${className}`} style={{ zIndex: 2 }}>

      {children}

    </div>
    <Footer frontmatter={footerNode.frontmatter} pathContext={pathContext}/>
  </>);
};

Wrapper.propTypes = {
  pathContext: PropTypes.object.isRequired,
  nodes: PropTypes.array.isRequired,
  node: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
};

Wrapper.defaultProps = {
  className: "",
};

export default Wrapper;
